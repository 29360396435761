.duplicator-body {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 29px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form {
  background-color: rgb(236, 234, 234);
  border-radius: 20px;
  box-sizing: border-box;
  height: 580px;
  padding: 20px;
  width: 320px;
}

.title {
  color: #289FD2;
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;

}

.subtitle {
  color: #289FD2;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 30px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: none;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: rgb(236, 234, 234);
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 120px;
}

.cut-short {
  width: 120px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: white;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: black;
}

.submit {
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 15px;
  outline: 0;
  text-align: center;
  width: 100%;
}

.validate{
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 30px;
  margin-top: 10px;
  outline: 0;
  text-align: center;
  width: 90px;
}

.input-validate{
  background-color: none;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: black;
  font-size: 18px;
  height: 30px;
  margin-top: 10px;
  margin-left: 5px;
  outline: 0;
  text-align: center;
  width: 120px;
}

.span-validator{
  margin-left: 10px;
  color: black;
  display: flex;
  align-items: center;
}

.submit:active {
  background-color: #06b;
}

.submitResponse{
    display: flex;
    align-items: center;
    justify-content: center;
}


