

* { 
  box-sizing:border-box; 
}

html {
  width: 100%; 
  height:100%; 
  overflow:hidden;
}


.login { 
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -180px;
  width:400px;
  height:300px;
  background-color: #77c8eb;
  border-radius: 10px;
}

.login h1 { 
    color: #fff; 
    text-shadow: 0 0 10px rgba(0,0,0,0.3); 
    letter-spacing:1px; text-align:center;
    margin-bottom: 30px;
}

input { 
  width: 300px; 
  margin-bottom: 10px; 
  background: rgba(0,0,0,0.3);
  border: none;
  display: flex;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2);
  
}

input:focus { box-shadow: inset 0 -5px 45px rgba(100,100,100,0.4), 0 1px 1px rgba(255,255,255,0.2); }

.btn{
    display: inline-block;
    width: 300px ;
    border-radius: 8px;
    height: 35px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    background-color: white;
    color: #54BCBD;
    font-size: larger;
}

