.table {
    position: relative;
    width: 90%;
    height: 40%;
    background-color: rgb(240, 247, 248);
    border-radius: 19px;
    padding: 0px , 10px , 10px, 10px;
    border-spacing: 0px;
    margin-left: 30px;
}

.tableRowHeader1{
 border-top-left-radius: 19px;
}
.tableRowHeader{
 border-top-left-radius: 19px;
 width: auto;
 background-color: #CEE7F2;
}

.tableRowHeader2{
    border-top-right-radius: 19px;
}

.addTag{
    position: relative;
    left: 2%;
    width: 100px;
    
}

.deleteButton{
    background-color: #d22828;
}

/* th {
    text-align: center;
    padding: 8px;
    background-color: #CEE7F2;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #8695A0;
    border-bottom: 1px solid #8695A0;
    font-weight: bold;

} */

/* td {
    text-align: center;
    padding: 8px;

} */

/* tr:hover {
    background-color: rgba(217, 217, 217, 0.24);
} */

/* .bu {
    Width: 75px;
    Height: 29px;
    background-color: #289FD2;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: x-small;
    cursor: pointer;
} */

/* .bu:hover{
    background-color: #289FD2;
} */
