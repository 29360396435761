.textMargin{
    width: 450px;
    height: 250px;
    background-color: white;
    color: black;
    overflow-x: auto;
    margin-bottom: 30px;
    font-size: small;
    text-align: center;
    border: 1px solid grey;
    padding: 10px;
}

.copyButton{
   width: 118px;
   height: 36.31px;
   border-radius: 10px;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 20px;
   letter-spacing: -0.02em;
}


