.layout{
  width: 100%;
  height: 100%;
}

.layoutSetion{
  grid-template-columns: 30vh;
  grid-template-rows: 10vh 90vh ;
  display: grid;
  box-sizing:border-box;
  height: 100%;
  width: 100%;
  
}

.header{
  display: flex;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: white;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  
}

.layoutButtonHeader{
  position: relative;
  right: 10px;
  Width: 75px;
  Height: 29px;
  background-color:#289FD2;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: x-small;
  cursor: pointer;
}

.aside{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    text-align: center;
    background-color: white;
    border-right: 8px solid whitesmoke;
}

.logo {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.links {
  top: 153px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #8695A0;
  margin-bottom: 300px;

}

.categories {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
}

.h4Categories{
  width: 80px;
}

.categories:hover{
  background-color: #CEE7F2;
}

.images {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.user {
  position: relative;
  display: flex;
  flex-direction: row;
  top: 200px;
}

.userImage {
  width: 45px;
  height: 45px;
  border-radius: 50%;

}

.userIcon {
  padding-right: 5px;
}

a {
  text-decoration: none;
  color: #8695A0;
}

.main{
  
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-color: whitesmoke;
  overflow-y: auto;
  
}


 button {
  Width: 75px;
  Height: 29px;
  background-color:#289FD2;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: x-small;
  cursor: pointer;
 }

