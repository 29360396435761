.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }
.wrapper{
    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    top: 40%;
    left: 50%;
    margin: -150px 0 0 -180px;
    width:500px;
    height:400px;
    background-color: white;
    border-radius: 32px;
    color: #289FD2;
    
}

.form{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width:463px;
    height:373px;
    color: #289FD2;
    
}

.header{
    margin: 0px 0px 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerButton{
  position: relative;
  left: 225px;
  top: 20px;
  height: 20px;
  width: 20px;
  border-radius: 180px;
  
}


.headerTitle{
    position: relative;
    bottom: 20px;
    padding-left: 10px;
}

.labelsSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 20px;
}

.formLabels{
    text-align: left;
    margin: 10px;
    display: flex;
    flex-direction: row;
}

.inputNames{
    width: 100px;

}

.inputButton{
    width: 249px;
    border-radius: 10px;
    border: 2px solid #289FD2; ;
    background-color: #F2F2F2 ;
    box-shadow: 0px 0px 0px 3px #EDEBFA;
    color: black;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
}

.submitButton{
   width: 118px;
   height: 36.31px;
   border: 2px solid #289FD2;
   border-radius: 10px;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 20px;
   letter-spacing: -0.02em;
}
